import React from 'react';
import ReactPaginate from 'react-paginate';
import styles from './Pagination.module.scss';

interface IPaginationProps {
  onPageChange: (page: number) => void;
  initialPage: number;
  pageCount: number;
}

export function Pagination({ initialPage, pageCount, onPageChange }: IPaginationProps) {
  return (
    <ReactPaginate
      className={styles.pagination}
      pageLinkClassName={styles.pagination__item}
      initialPage={initialPage - 1}
      onPageChange={({ selected }) => onPageChange(selected + 1)}
      pageCount={pageCount}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
      previousLabel={null}
      nextLabel={null}
      breakLabel={null}
      hrefBuilder={(pageIndex) => `${window.location.pathname}?page=${pageIndex}`}
      disableInitialCallback
    />
  );
}
