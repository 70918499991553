import { useEffect, useRef } from 'react';

// Make sure hook is initialized only once at a given time.
let isAttached = false;

/**
 * Detect if element has received focus from a keyboard event.
 *
 * The hook adds a data-keyboard-focus="true" to any keyboard focused element.
 */
export default function useKeyboardFocusDetection(): void {
  const hadKeyboardEventRef = useRef<boolean>(false);

  useEffect(() => {
    if (isAttached) {
      return undefined;
    }

    isAttached = true;

    const onKeyDown = (e: KeyboardEvent) => {
      if (!e.altKey && !e.ctrlKey && !e.metaKey) {
        hadKeyboardEventRef.current = true;
      }
    };

    const onFocus = (e: FocusEvent) => {
      setTimeout(() => {
        if (hadKeyboardEventRef.current && e.target instanceof HTMLElement) {
          e.target.dataset.keyboardFocus = 'true';
          hadKeyboardEventRef.current = false;
        }
      }, 0);
    };

    const onBlur = (e: FocusEvent) => {
      if (e.target instanceof HTMLElement && e.target.dataset.keyboardFocus) {
        delete e.target.dataset.keyboardFocus;
      }
    };

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('focus', onFocus, true);
    document.addEventListener('blur', onBlur, true);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('focus', onFocus, true);
      document.removeEventListener('blur', onBlur, true);

      isAttached = false;
    };
  }, [isAttached]);
}
