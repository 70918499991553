import { useQuery } from 'react-query';
import { BlocksContent } from '@strapi/blocks-react-renderer';

export interface IServicePageContent {
  id: number;
  attributes: {
    title: string;
    slug: string;
    body: BlocksContent;
    icon: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
  };
}

export function useLoadServicesContent() {
  return useQuery({
    queryKey: ['services'],
    queryFn: () =>
      fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/api/services?populate=icon`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_API_KEY}`
        }
      })
        .then((res) => res.json())
        .then((x) => x.data as IServicePageContent[])
  });
}
