import React from 'react';
import { LoadingSkeleton } from '../LoadingSkeleton/LoadingSkeleton';
import styles from './LoadingPageContent.module.scss';

interface ILoadingPageContentProps {
  servicePage?: boolean;
}

export default function LoadingPageContent({ servicePage }: ILoadingPageContentProps) {
  return (
    <>
      {servicePage ? <LoadingSkeleton className={styles['loader__service-icon']} /> : null}

      <LoadingSkeleton title />
      {Array(10)
        .fill(null)
        .map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <LoadingSkeleton key={i} paragraph />
        ))}
    </>
  );
}
