import { BlocksContent, BlocksRenderer } from '@strapi/blocks-react-renderer';
import { Link } from 'react-router-dom';
import React from 'react';
import styles from './BlogTextBlock.module.scss';

/**
 * Check if given url belongs to our website.
 */
function isOwnLink(url: string): boolean {
  try {
    // Assume it's a relative path.
    if (url?.startsWith('/')) {
      return true;
    }

    // Absolute urls.
    const parsedUrl = new URL(url);

    return parsedUrl.hostname.toLowerCase() === window.location.hostname.toLowerCase();
  } catch (e) {
    return false;
  }
}

interface IBlogTextBlockProps {
  body: BlocksContent;
}

export default function BlogTextBlock({ body }: IBlogTextBlockProps) {
  return (
    <div className={styles.content}>
      <BlocksRenderer
        content={body}
        blocks={{
          // eslint-disable-next-line react/no-unstable-nested-components
          link: ({ children, url }) =>
            isOwnLink(url) ? (
              <Link to={url}>{children}</Link>
            ) : (
              <a href={url} target="_blank" rel="noreferrer">
                {children}
              </a>
            ),
          // eslint-disable-next-line react/no-unstable-nested-components
          image: ({ image }) => <img src={image.url} alt={image.alternativeText!} />
        }}
      />
    </div>
  );
}
