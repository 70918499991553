import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Page from '../../components/Page/Page';
import styles from './ServicesPage.module.scss';
import PageTitle from '../../components/PageTitle/PageTitle';
import { LoadingSkeleton } from '../../components/LoadingSkeleton/LoadingSkeleton';
import { useLoadServicesContent } from '../../api/services';
import { civilLawRoute, servicesRoute } from '../../lib/routes';
import ErrorPage from '../ErrorPage/ErrorPage';
import { resolveUrl } from '../../lib/resolveUrl';

export default function ServicesPage(): React.ReactElement {
  const { isLoading, error, data } = useLoadServicesContent();

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Page pageTitle={servicesRoute.name}>
      {isLoading || !data ? (
        <>
          <LoadingSkeleton title />
          <ul className={styles.page__list}>
            {Array(6)
              .fill(null)
              .map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i} className={styles.page__item}>
                  <LoadingSkeleton className={styles['page__item-skeleton']} />
                </li>
              ))}
          </ul>
        </>
      ) : (
        <>
          <PageTitle>{servicesRoute.name}</PageTitle>

          <nav>
            <ul className={styles.page__list}>
              {data.map((service) => (
                <li key={service.attributes.slug} className={styles.page__item}>
                  <Link to={service.attributes.slug} className={styles.page__link}>
                    <img
                      src={resolveUrl(service.attributes.icon.data.attributes.url)}
                      alt={service.attributes.title}
                      className={clsx({
                        [styles.page__civil]: service.attributes.slug === civilLawRoute.path
                      })}
                    />
                    <span>{service.attributes.title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </>
      )}
    </Page>
  );
}
