import React from 'react';
import { useLocation } from 'react-router-dom';
import Page from '../../components/Page/Page';
import ErrorPage from '../ErrorPage/ErrorPage';
import { useFindBlogPageBySlug } from '../../api/blog';
import LoadingPageContent from '../../components/LoadingPageContent/LoadingPageContent';
import PageTitle from '../../components/PageTitle/PageTitle';
import styles from './BlogPage.module.scss';
import { StrapiFrontendWidgetType } from '../../lib/constants';
import { resolveUrl } from '../../lib/resolveUrl';
import { ArticleAuthorSection } from '../../components/ArticleAuthorSection/ArticleAuthorSection';
import BlogTextBlock from '../../components/BlogTextBlock/BlogTextBlock';
import BlogContactSection from '../../components/BlogContactSection/BlogContactSection';

export default function BlogPage() {
  const location = useLocation();
  const { isLoading, error, data: content } = useFindBlogPageBySlug(location.pathname);

  if (error) {
    return <ErrorPage />;
  }

  if (!content && !isLoading) {
    return <ErrorPage notFound />;
  }

  return (
    <Page pageTitle={content?.attributes.title} pageDescription={content?.attributes.excerpt}>
      {isLoading || !content ? (
        <LoadingPageContent />
      ) : (
        <>
          <article className={styles.page__article}>
            <PageTitle className={styles.page__title} alignLeft>
              {content.attributes.title}
            </PageTitle>

            <ArticleAuthorSection
              author={content.attributes.author.data}
              createdAt={content.attributes.createdAt}
            />

            <img
              src={resolveUrl(content.attributes.cover.data.attributes.url)}
              alt={content.attributes.title}
              className={styles.page__cover}
            />

            {content.attributes.body.map((item) => {
              const key = `${item.id}/${item.__component}`;

              switch (item.__component) {
                case StrapiFrontendWidgetType.textBlock:
                  return <BlogTextBlock key={key} body={item.body} />;
                default:
                  return null;
              }
            })}
          </article>

          <BlogContactSection />
        </>
      )}
    </Page>
  );
}
