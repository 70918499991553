import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function getInitialPage(): number {
  const page = parseInt(new URLSearchParams(window.location.search).get('page')!, 10);

  return Number.isFinite(page) && page > 0 ? page : 1;
}

export function usePagination(): [number, (page: number) => void] {
  const navigate = useNavigate();
  const [page, setPage] = useState(getInitialPage());

  const updateUrl = (nextPage: number) => {
    const params = new URLSearchParams(window.location.search);

    params.set('page', `${nextPage}`);

    navigate(`${window.location.pathname}?${params.toString()}`);
  };

  return [
    page,
    useCallback((nextPage) => {
      updateUrl(nextPage);
      setPage(nextPage);
    }, [])
  ];
}
