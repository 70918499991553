import React from 'react';
import { useLocation } from 'react-router-dom';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import Page from '../../components/Page/Page';
import styles from './ServicePage.module.scss';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useLoadServicesContent } from '../../api/services';
import ErrorPage from '../ErrorPage/ErrorPage';
import LoadingPageContent from '../../components/LoadingPageContent/LoadingPageContent';
import { resolveUrl } from '../../lib/resolveUrl';

export function ServicePage() {
  const { isLoading, error, data } = useLoadServicesContent();
  const location = useLocation();

  const content = data?.find((x) => location.pathname === x.attributes.slug);

  if (error) {
    return <ErrorPage />;
  }

  if (!content && !isLoading) {
    return <ErrorPage notFound />;
  }

  return (
    <Page pageTitle={content?.attributes.title}>
      {isLoading || !content ? (
        <LoadingPageContent servicePage />
      ) : (
        <>
          <img
            src={resolveUrl(content.attributes.icon.data.attributes.url)}
            alt={content.attributes.title}
            className={styles.page__icon}
          />

          <PageTitle>{content.attributes.title}</PageTitle>

          <BlocksRenderer
            content={content.attributes.body}
            blocks={{
              // eslint-disable-next-line react/no-unstable-nested-components
              paragraph: ({ children }) => <p className={styles.page__summary}>{children}</p>,
              // eslint-disable-next-line react/no-unstable-nested-components
              list: ({ children }) => <ul className={styles.page__list}>{children}</ul>,
              // eslint-disable-next-line react/no-unstable-nested-components,react/prop-types
              'list-item': ({ children }) => <li className={styles.page__item}>{children}</li>
            }}
          />
        </>
      )}
    </Page>
  );
}
