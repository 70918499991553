import React from 'react';
import { ReactComponent as ShieldIcon } from '@material-design-icons/svg/filled/verified_user.svg';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import Page from '../../components/Page/Page';
import PageTitle from '../../components/PageTitle/PageTitle';
import styles from './StatementPage.module.scss';
import { useLoadStatementContent } from '../../api/statement';
import ErrorPage from '../ErrorPage/ErrorPage';
import LoadingPageContent from '../../components/LoadingPageContent/LoadingPageContent';

export default function StatementPage() {
  const { isLoading, error, data } = useLoadStatementContent();

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Page pageTitle={data?.attributes.title}>
      {isLoading || !data ? (
        <LoadingPageContent />
      ) : (
        <>
          <PageTitle>{data.attributes.title}</PageTitle>

          <BlocksRenderer
            content={data.attributes.body}
            blocks={{
              // eslint-disable-next-line react/no-unstable-nested-components
              list: ({ children }) => <ul className={styles.page__list}>{children}</ul>,
              // eslint-disable-next-line react/no-unstable-nested-components,react/prop-types
              'list-item': ({ children }) => (
                <li className={styles.page__item}>
                  <ShieldIcon className={styles.page__icon} />
                  {children}
                </li>
              )
            }}
          />
        </>
      )}
    </Page>
  );
}
