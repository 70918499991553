import React, { useEffect, useRef, useState, useId } from 'react';
import clsx from 'clsx';
import { ReactComponent as CloseIcon } from '@material-design-icons/svg/filled/close.svg';
import useTrapFocus from '../../hooks/useTrapFocus';
import styles from './ContactModal.module.scss';

interface ModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export default function ContactModal({ isOpen = false, onClose }: ModalProps) {
  const [isClosing, setIsClosing] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const id = useId();

  // Handle Esc button press.
  useEffect(() => {
    if (!isOpen) {
      return undefined;
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        e.preventDefault();
        setIsClosing(true);
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => document.removeEventListener('keydown', onKeyDown);
  }, [isOpen]);

  useTrapFocus({ isEnabled: isOpen, ref });

  if (!isOpen) {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={clsx(styles.overlay, { [styles['overlay--closing']]: isClosing })}
      onClick={(event) => {
        if (ref?.current && !ref.current.contains(event.target as HTMLElement)) {
          setIsClosing(true);
        }
      }}
      onAnimationEnd={() => {
        if (isClosing) {
          onClose();
          setIsClosing(false);
        }
      }}
    >
      <div
        className={clsx(styles.modal, {
          [styles['modal--closing']]: isClosing
        })}
        role="dialog"
        aria-labelledby={`${id}-title`}
        aria-describedby={`${id}-body`}
        aria-modal
        ref={ref}
      >
        <header className={styles.modal__header}>
          <button
            className={styles.modal__close}
            onClick={() => onClose()}
            aria-label="Закрити"
            type="button"
          >
            <CloseIcon />
          </button>

          <h4 className={styles.modal__title} id={`${id}-title`}>
            Дякуємо!
          </h4>
        </header>

        <div className={styles.modal__body} id={`${id}-body`}>
          <p>
            Ваше повідомлення успішно відправлено! Очікуйте, ми з Вами звяжимось протягом декількох
            годин.
          </p>
        </div>
      </div>
    </div>
  );
}
