import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Page from '../../components/Page/Page';
import { blogListPage } from '../../lib/routes';
import styles from './BlogListPage.module.scss';
import { useLoadBlogs } from '../../api/blog';
import ErrorPage from '../ErrorPage/ErrorPage';
import LoadingPageContent from '../../components/LoadingPageContent/LoadingPageContent';
import PageTitle from '../../components/PageTitle/PageTitle';
import { ArticlePublicationDate } from '../../components/ArticlePublicationDate/ArticlePublicationDate';
import { resolveUrl } from '../../lib/resolveUrl';
import { Pagination } from '../../components/Pagination/Pagination';
import { usePagination } from '../../hooks/usePagination';

export default function BlogListPage() {
  const [page, setPage] = usePagination();
  const { isLoading, error, data } = useLoadBlogs(page);

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Page pageTitle={isLoading ? undefined : blogListPage.name}>
      {isLoading || !data?.content ? (
        <LoadingPageContent />
      ) : (
        <>
          <PageTitle>{blogListPage.name}</PageTitle>
          {data.content.map((item) => {
            const href = item.attributes.slug;

            return (
              <article className={styles.page__article} key={item.id}>
                <Link className={styles.page__photo} to={href}>
                  <img
                    src={resolveUrl(item.attributes.cover.data.attributes.url)}
                    alt={item.attributes.cover.data.attributes.alternativeText!}
                  />
                </Link>

                <div>
                  <Link className={styles.page__title} to={href}>
                    {item.attributes.title}
                  </Link>

                  <ArticlePublicationDate
                    className={styles.page__time}
                    date={item.attributes.createdAt}
                  />

                  <p>{item.attributes.excerpt}</p>
                </div>
              </article>
            );
          })}

          <Pagination initialPage={page} pageCount={data.pageCount} onPageChange={setPage} />
        </>
      )}
    </Page>
  );
}
