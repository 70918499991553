import React from 'react';
import styles from './Footer.module.scss';
import FooterContactForm from '../FooterContactForm/FooterContactForm';
import { footerSocialLinks } from '../../lib/footerSocialLinks';

export default function Footer() {
  return (
    <footer className={styles.wrapper}>
      <div className={styles.footer}>
        <FooterContactForm />

        <nav>
          <ul className={styles.footer__list}>
            {footerSocialLinks.map((link) => {
              const Icon = link.icon;

              return (
                <li key={link.href} className={styles.footer__item}>
                  <a
                    href={link.href}
                    aria-label={link.label}
                    className={styles.footer__link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon />
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </footer>
  );
}
