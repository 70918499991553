import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { ReactComponent as MenuIcon } from '@material-design-icons/svg/filled/menu.svg';
import { ReactComponent as PhoneIcon } from '@material-design-icons/svg/filled/local_phone.svg';
import { ReactComponent as EmailIcon } from '@material-design-icons/svg/filled/email.svg';
import { ReactComponent as LocationIcon } from '@material-design-icons/svg/filled/location_on.svg';
import styles from './Header.module.scss';
import { menuItems } from '../../lib/menuItems';
import MobileMenu from '../MobileMenu/MobileMenu';
import { homepageRoute } from '../../lib/routes';

export default function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  useEffect(() => {
    const handler = () => {
      setIsScrolled(window.scrollY > 0);
    };

    document.addEventListener('scroll', handler);

    return () => {};
  }, []);

  return (
    <>
      <header
        className={clsx(styles.wrapper, {
          [styles['wrapper--scrolled']]: isScrolled || isMobileMenuOpen
        })}
      >
        <div className={styles.header}>
          <div className={styles.header__left}>
            <Link to="/" className={styles.header__homepage} aria-label={homepageRoute.name}>
              <img
                src={`${process.env.PUBLIC_URL}/images/logotype.svg`}
                className={styles.header__logo}
                alt="SMK LAW Логотип"
              />
            </Link>

            <div className={styles.header__info}>
              <span className={styles.header__name}>Артур Сімейко</span>
              <span className={styles.header__role}>адвокат</span>
            </div>
          </div>

          <div className={styles.header__right}>
            <button
              onClick={() => setIsMobileMenuOpen((v) => !v)}
              className={styles.header__mobile}
              aria-label="Відкрити Меню"
              type="button"
            >
              <MenuIcon />
            </button>

            <nav>
              <ul className={styles.header__menu}>
                {menuItems.map(({ label, href }) => (
                  <li key={href} className={styles['header__menu-item']}>
                    <NavLink
                      to={href}
                      className={({ isActive }) =>
                        clsx(styles['header__menu-link'], {
                          [styles['header__menu-link--homepage']]: href === homepageRoute.path,
                          [styles['header__menu-link--active']]: isActive
                        })
                      }
                    >
                      {label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </nav>

            <ul className={styles.header__contact}>
              <li className={styles['header__contact-item']}>
                <a className={styles['header__contact-link']} href="tel:+380505860456">
                  <PhoneIcon />
                  38 (050) 586 04 56
                </a>
              </li>

              <li className={styles['header__contact-item']}>
                <a className={styles['header__contact-link']} href="mailto:welcome@smk-law.com.ua">
                  <EmailIcon />
                  welcome@smk-law.com.ua
                </a>
              </li>

              <li className={styles['header__contact-item']}>
                <a
                  className={styles['header__contact-link']}
                  href="https://goo.gl/maps/ena4DgGXzZ3iAsKe6"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LocationIcon />
                  м.Київ
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <MobileMenu isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} />
    </>
  );
}
