import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import styles from './MobileMenu.module.scss';
import { menuItems } from '../../lib/menuItems';
import useTrapFocus from '../../hooks/useTrapFocus';
import useWindowResize from '../../hooks/useWindowResize';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function MobileMenu({ isOpen, onClose }: MobileMenuProps) {
  const ref = useRef<HTMLDivElement>(null);

  // Prevent body scroll when mobile is open.
  useEffect(() => {
    const body = document.querySelector('body') as HTMLBodyElement;

    if (isOpen) {
      body.classList.add(styles.body);
    } else {
      body.classList.remove(styles.body);
    }
  }, [isOpen]);

  // Close on Esc press.
  useEffect(() => {
    if (!isOpen) {
      return undefined;
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        e.preventDefault();
        onClose();
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => document.removeEventListener('keydown', onKeyDown);
  }, [isOpen]);

  // Trap focus.
  useTrapFocus({ ref, isEnabled: isOpen });

  // Close when window gets resized.
  useWindowResize(() => {
    if (window.innerWidth >= 1200) {
      onClose();
    }
  }, isOpen);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.menu} ref={ref}>
      <nav className={styles.menu__nav}>
        <ul className={styles.menu__list}>
          {menuItems.map(({ label, href }) => (
            <li key={href} className={styles.menu__item}>
              <NavLink
                onClick={() => onClose()}
                className={({ isActive }) =>
                  clsx(styles.menu__link, {
                    [styles['menu__link--active']]: isActive
                  })
                }
                to={href}
              >
                {label}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>

      <ul className={styles.menu__contact}>
        <li className={styles['menu__contact-item']}>
          <a className={styles['menu__contact-link']} href="tel:+380505860456">
            38 (050) 586 04 56
          </a>
        </li>

        <li className={styles['menu__contact-item']}>
          <a className={styles['menu__contact-link']} href="mailto:welcome@smk-law.com.ua">
            welcome@smk-law.com.ua
          </a>
        </li>

        <li className={styles['menu__contact-item']}>
          <a
            className={styles['menu__contact-link']}
            href="https://goo.gl/maps/ena4DgGXzZ3iAsKe6"
            target="_blank"
            rel="noreferrer"
          >
            м.Київ
          </a>
        </li>
      </ul>
    </div>
  );
}
