import { useMutation } from 'react-query';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface IContactFormData {
  email: string;
  form: string;
  name?: string;
  message?: string;
}

export function useSubmitContactForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  return useMutation({
    mutationFn: async (data: IContactFormData) =>
      fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/api/ezforms/submit`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_API_KEY}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: await executeRecaptcha!(),
          formData: data
        })
      })
  });
}
