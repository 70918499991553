import getFocusableElements from './getFocusableElements';
import isElementHidden from './isElementHidden';

/**
 * Get Tab accessible elements.
 *
 * @param   {HTMLElement}   element HTML element
 * @returns {HTMLElement[]}         Tabbable HTML elements
 */
export default function getTabbableElements(element: HTMLElement): HTMLElement[] {
  return getFocusableElements(element).filter((el) => !isElementHidden(el));
}
