import { TelegramFill, FacebookFill, InstagramFill, Icon } from 'akar-icons';

export interface IFooterSocialLink {
  label: string;
  href: string;
  icon: Icon;
}

export const footerSocialLinks: IFooterSocialLink[] = [
  {
    label: 'Instagram',
    href: 'https://www.instagram.com/advokatsimeiko/',
    icon: InstagramFill
  },
  {
    label: 'Telegram',
    href: 'https://t.me/simeiik',
    icon: TelegramFill
  },
  {
    label: 'Facebook',
    href: 'https://www.facebook.com/smklaw.com.ua',
    icon: FacebookFill
  }
];
