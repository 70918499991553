import React from 'react';
import clsx from 'clsx';
import styles from './LoadingSkeleton.module.scss';

interface ILoadingSkeletonProps {
  className?: string;
  title?: boolean;
  paragraph?: boolean;
}

export function LoadingSkeleton({ className, title, paragraph }: ILoadingSkeletonProps) {
  return (
    <div
      className={clsx(styles.skeleton, className, {
        [styles['skeleton--title']]: title,
        [styles['skeleton--paragraph']]: paragraph
      })}
      aria-busy
    />
  );
}
