import React from 'react';
import clsx from 'clsx';
import styles from './PageTitle.module.scss';

interface PageTitleProps {
  children: React.ReactNode;
  alignLeft?: boolean;
  className?: string;
}

export default function PageTitle({
  children,
  className,
  alignLeft
}: PageTitleProps): React.ReactElement {
  return (
    <h1
      className={clsx(styles.title, className, {
        [styles['title--left']]: alignLeft
      })}
    >
      {children}
    </h1>
  );
}
