import React from 'react';
import { Link } from 'react-router-dom';
import { ArticlePublicationDate } from '../ArticlePublicationDate/ArticlePublicationDate';
import { IArticleAuthor } from '../../api/blog';
import styles from './ArticleAuthorSection.module.scss';
import { resolveUrl } from '../../lib/resolveUrl';
import { teamRoute } from '../../lib/routes';

interface IArticleAuthorSectionProps {
  author: IArticleAuthor;
  createdAt: string;
}

export function ArticleAuthorSection({ author, createdAt }: IArticleAuthorSectionProps) {
  return (
    <section className={styles.section}>
      <img
        src={
          author
            ? resolveUrl(author.attributes.photo.data.attributes.formats.thumbnail.url)
            : undefined
        }
        alt={author?.attributes.photo.data.attributes.alternativeText!}
        className={styles.section__photo}
      />

      <div className={styles.section__details}>
        <Link to={teamRoute.path} className={styles.section__name}>
          {author?.attributes.fullName ?? '-'}
        </Link>

        <ArticlePublicationDate date={createdAt} className={styles.section__date} />
      </div>
    </section>
  );
}
