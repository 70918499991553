/**
 * Gets keyboard focusable elements.
 *
 * @param   {HTMLElement}   element HTML Element
 * @returns {HTMLElement[]}         Array with focusable elements
 */
export default function getFocusableElements(element: HTMLElement): HTMLElement[] {
  return [
    ...element.querySelectorAll(
      'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
    )
  ].filter((el) => !el.hasAttribute('disabled')) as HTMLElement[];
}
