import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import styles from './Page.module.scss';

interface PageProps {
  children: React.ReactNode;
  pageTitle?: string;
  pageDescription?: string;
  className?: string;
  fullWidth?: boolean;
}

export default function Page({
  children,
  pageTitle,
  pageDescription,
  className,
  fullWidth
}: PageProps) {
  const location = useLocation();

  // Update page title.
  useEffect(() => {
    document.title = `${pageTitle ?? 'Завантаження...'} | SMK LAW`;
  }, [pageTitle]);

  // Update description tag link.
  useEffect(() => {
    const meta = document.head.querySelector<HTMLMetaElement>('meta[name="description"]');

    // No page description. Remove tag and exit.
    if (!pageDescription) {
      if (meta) {
        meta.remove();
      }

      return;
    }

    if (meta) {
      meta.content = pageDescription;
    } else {
      const newMeta = document.createElement('meta');

      newMeta.name = 'description';
      newMeta.content = pageDescription;

      document.head.appendChild(newMeta);
    }
  }, [pageDescription]);

  // Update canonical link.
  useEffect(() => {
    const link = document.head.querySelector<HTMLLinkElement>('link[rel="canonical"]');
    let href = `${window.location.origin}${location.pathname}`;

    // Trim trailing slash.
    if (href.endsWith('/')) {
      href = href.substring(0, href.length - 1);
    }

    if (link) {
      link.href = href;
    } else {
      const newLink = document.createElement('link');

      newLink.href = href;
      newLink.rel = 'canonical';

      document.head.appendChild(newLink);
    }
  }, [location.pathname]);

  return (
    <main
      className={clsx(styles.page, className, {
        [styles['page--constrain']]: !fullWidth
      })}
    >
      {children}
    </main>
  );
}
