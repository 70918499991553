import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { ReactComponent as PhoneIcon } from '@material-design-icons/svg/filled/local_phone.svg';
import { ReactComponent as EmailIcon } from '@material-design-icons/svg/filled/email.svg';
import { ReactComponent as LocationIcon } from '@material-design-icons/svg/filled/location_on.svg';
import clsx from 'clsx';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { contactRoute } from '../../lib/routes';
import styles from './ContactPage.module.scss';
import Page from '../../components/Page/Page';
import PageTitle from '../../components/PageTitle/PageTitle';
import ContactModal from '../../components/ContactModal/ContactModal';
import { useSubmitContactForm } from '../../api/forms';

const mapSettings = {
  center: {
    lat: 50.46716025881643,
    lng: 30.470661933530366
  },
  zoom: 11
};

export default function ContactPage() {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const mutation = useSubmitContactForm();
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Display modal on success.
  useEffect(() => {
    if (mutation.isSuccess) {
      setShowModal(true);
      setName('');
      setEmail('');
      setMessage('');
    }
  }, [mutation.isSuccess]);

  return (
    <Page pageTitle={contactRoute.name}>
      <PageTitle>{contactRoute.name}</PageTitle>

      <div className={styles.page__columns}>
        <form
          className={styles.page__column}
          onSubmit={(e) => {
            e.preventDefault();

            mutation.mutate({
              form: 'contact-page',
              message,
              email,
              name
            });
          }}
        >
          <h2 className={styles.page__title}>Напишіть нам</h2>

          <input
            className={styles.page__input}
            onChange={(e) => setName(e.target.value)}
            placeholder="Ім'я"
            type="text"
            value={name}
            minLength={2}
            maxLength={255}
            autoComplete="name"
            required
          />

          <input
            className={styles.page__input}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Електронна пошта"
            value={email}
            minLength={3}
            maxLength={255}
            autoComplete="email"
            required
          />

          <textarea
            className={styles.page__textarea}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Ваше повідомлення"
            value={message}
            rows={8}
            maxLength={10000}
          />

          <button
            className={clsx(styles.page__submit, {
              [styles['page__submit--loading']]: mutation.isLoading
            })}
            type="submit"
            disabled={mutation.isLoading || mutation.isSuccess || !executeRecaptcha}
          >
            Відправити
          </button>
        </form>

        <div className={styles.page__column}>
          <h2 className={styles.page__title}>Зв&apos;яжіться з нами</h2>

          <ul className={styles.page__list}>
            <li className={styles.page__item}>
              <PhoneIcon className={styles.page__icon} />

              <a className={styles.page__link} href="tel:+380505860456">
                38 (050) 586 04 56
              </a>
            </li>

            <li className={styles.page__item}>
              <EmailIcon className={styles.page__icon} />

              <a className={styles.page__link} href="mailto:welcome@smk-law.com.ua">
                welcome@smk-law.com.ua
              </a>
            </li>

            <li className={styles.page__item}>
              <LocationIcon className={styles.page__icon} />

              <div>
                <a
                  className={styles.page__link}
                  href="https://goo.gl/maps/ena4DgGXzZ3iAsKe6"
                  target="_blank"
                  rel="noreferrer"
                >
                  04119, м. Київ,
                  <br />
                  вул. Деревлянська, буд. 8, кім.22.
                </a>

                <span className={styles.page__note}>
                  (окремий вхід з лівої сторони будинку, 10-й поверх)
                </span>
              </div>
            </li>
          </ul>

          <div className={styles.page__map}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
                language: 'uk'
              }}
              defaultCenter={mapSettings.center}
              defaultZoom={mapSettings.zoom}
              onGoogleApiLoaded={({ map, maps }) => {
                // eslint-disable-next-line no-new
                new maps.Marker({
                  position: { lat: mapSettings.center.lat, lng: mapSettings.center.lng },
                  map
                });
              }}
              options={{ zoomControl: false, fullscreenControl: false }}
              yesIWantToUseGoogleMapApiInternals
            />
          </div>
        </div>
      </div>

      <ContactModal onClose={() => setShowModal(false)} isOpen={showModal} />
    </Page>
  );
}
