import React from 'react';
import Page from '../../components/Page/Page';
import styles from './ErrorPage.module.scss';
import { notFoundRoute } from '../../lib/routes';

interface IErrorPageProps {
  notFound?: boolean;
}

export default function ErrorPage({ notFound }: IErrorPageProps) {
  const title = notFound ? notFoundRoute.name : 'Сталася помилка!';

  return (
    <Page pageTitle={title} className={styles.page}>
      <h1 className={styles.page__title}>{title}</h1>

      {!notFound ? <h2>Спробуйте ще раз пізніше.</h2> : null}
    </Page>
  );
}
