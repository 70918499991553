import { useQuery } from 'react-query';
import { IFrontendTeamColumnsComponent, IFrontendTextBlockComponent } from '../types/strapi';

export interface ITeamPageContent {
  id: number;
  attributes: {
    title: string;
    body: (IFrontendTextBlockComponent | IFrontendTeamColumnsComponent)[];
  };
}

export function useLoadTeamContent() {
  return useQuery({
    queryKey: ['team'],
    queryFn: () =>
      fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/api/team?populate=deep,4`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_API_KEY}`
        }
      })
        .then((res) => res.json())
        .then((x) => x.data as ITeamPageContent)
  });
}
