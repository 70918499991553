import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { ReactComponent as VoiceOverIcon } from '@material-design-icons/svg/filled/record_voice_over.svg';
import { ReactComponent as DiversityIcon } from '@material-design-icons/svg/filled/diversity_3.svg';
import { ReactComponent as CarIcon } from '@material-design-icons/svg/filled/car_crash.svg';
import { ReactComponent as FamilyIcon } from '@material-design-icons/svg/filled/family_restroom.svg';
import { ReactComponent as BusinessIcon } from '@material-design-icons/svg/filled/business_center.svg';
import { ReactComponent as PoliceIcon } from '@material-design-icons/svg/filled/local_police.svg';
import Page from '../../components/Page/Page';
import {
  administrativeLawRoute,
  businessLawRoute,
  civilLawRoute,
  commercialLawRoute,
  contactRoute,
  criminalLawRoute,
  familyLawRoute,
  homepageRoute
} from '../../lib/routes';
import styles from './Homepage.module.scss';

export default function Homepage(): React.ReactElement {
  return (
    <Page
      pageTitle={homepageRoute.name}
      pageDescription={homepageRoute.description}
      className={styles.homepage}
      fullWidth
    >
      <div className={styles.homepage__background}>
        <div className={clsx(styles.section, styles.homepage__top)}>
          <h1 className={styles.homepage__title}>
            Наші досвід, практика, знання, відповідальність та злагодженість - дозволяють нам
            здійснювати ефективний захист Ваших прав та охоронюваних законом інтересів.
          </h1>

          <Link className={styles.homepage__contact} to={contactRoute.path}>
            Зв&apos;язатись
          </Link>
        </div>
      </div>

      <div className={clsx(styles.section, styles.homepage__cards)}>
        <div className={styles.homepage__card}>
          <img src={`${process.env.PUBLIC_URL}/images/bag-and-stars.svg`} alt="Bag & Stars" />

          <p>25+ років практичного досвіду та успішної адвокатської практики</p>
        </div>

        <div className={styles.homepage__card}>
          <img src={`${process.env.PUBLIC_URL}/images/person-and-star.svg`} alt="Person & Star" />

          <p>Правова допомога адвоката в Києві</p>
        </div>
      </div>

      <nav className={styles.section}>
        <ul className={styles.homepage__list}>
          <li className={styles.homepage__item}>
            <Link to={commercialLawRoute.path} className={styles.homepage__link}>
              <span className={styles.homepage__icon}>
                <VoiceOverIcon />
              </span>

              <span>Адвокат у господарських справах</span>
            </Link>
          </li>

          <li className={styles.homepage__item}>
            <Link to={administrativeLawRoute.path} className={styles.homepage__link}>
              <span className={styles.homepage__icon}>
                <DiversityIcon />
              </span>

              <span>Адвокат в адміністративних справах</span>
            </Link>
          </li>

          <li className={styles.homepage__item}>
            <Link to={civilLawRoute.path} className={styles.homepage__link}>
              <span className={styles.homepage__icon}>
                <CarIcon />
              </span>

              <span>Адвокат у цивільних справах</span>
            </Link>
          </li>

          <li className={styles.homepage__item}>
            <Link to={familyLawRoute.path} className={styles.homepage__link}>
              <span className={styles.homepage__icon}>
                <FamilyIcon />
              </span>

              <span>Адвокат у сімейних спорах</span>
            </Link>
          </li>

          <li className={styles.homepage__item}>
            <Link to={businessLawRoute.path} className={styles.homepage__link}>
              <span className={styles.homepage__icon}>
                <BusinessIcon />
              </span>

              <span>Адвокат із захисту бізнесу</span>
            </Link>
          </li>

          <li className={styles.homepage__item}>
            <Link to={criminalLawRoute.path} className={styles.homepage__link}>
              <span className={styles.homepage__icon}>
                <PoliceIcon />
              </span>

              <span>Адвокат у кримінальних справах</span>
            </Link>
          </li>
        </ul>
      </nav>
    </Page>
  );
}
