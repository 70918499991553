import React from 'react';

interface IBlogTimeLabelProps {
  date: string;
  className?: string;
}

export function ArticlePublicationDate({ date, className }: IBlogTimeLabelProps) {
  const createdAt = new Date(date);

  return (
    <time className={className} dateTime={createdAt.toISOString().split('T')[0]}>
      {createdAt.toLocaleDateString('uk-UA')}
    </time>
  );
}
