import React, { useEffect } from 'react';
import getTabbableElements from '../lib/getTabbableElements';

interface UseTrapFocusProps {
  ref: React.RefObject<HTMLElement>;
  isEnabled: boolean;
}

/**
 * Trap focus inside HTML element.
 *
 * @param {{current: HTMLElement}}  ref         Ref with HTML element
 * @param {boolean}                 [isEnabled] Enable or disable hook
 */
export default function useTrapFocus({ ref, isEnabled = true }: UseTrapFocusProps): void {
  useEffect(() => {
    if (!ref.current || !isEnabled) {
      return undefined;
    }

    // Always focus first element on run when focus isn't present.
    if (!ref.current.contains(document.activeElement)) {
      getTabbableElements(ref.current)[0]?.focus();
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.code !== 'Tab' || !ref.current) {
        return;
      }

      const tabbableElements = getTabbableElements(ref.current);
      const firstElement = tabbableElements[0];
      const lastElement = tabbableElements[tabbableElements.length - 1];

      if (
        e.shiftKey &&
        (document.activeElement === firstElement || !ref.current.contains(e.target as HTMLElement))
      ) {
        e.preventDefault();
        lastElement?.focus();
      }

      if (
        !e.shiftKey &&
        (document.activeElement === lastElement || !ref.current.contains(e.target as HTMLElement))
      ) {
        e.preventDefault();
        firstElement?.focus();
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => document.removeEventListener('keydown', onKeyDown);
  }, [ref, isEnabled]);
}
