import React from 'react';
import { BlocksContent } from '@strapi/blocks-react-renderer';
import styles from './BlogContactSection.module.scss';
import { contactRoute } from '../../lib/routes';
import BlogTextBlock from '../BlogTextBlock/BlogTextBlock';

// It's a bit hacky, but at least this way we don't need to copy & paste css.
const body: BlocksContent = [
  {
    type: 'heading',
    children: [
      {
        type: 'text',
        text: 'Потрібна правова допомога?'
      }
    ],
    level: 2
  },
  {
    type: 'paragraph',
    children: [
      {
        type: 'text',
        text: 'Маєте питання або потребуєте правової допомоги? Ми готові Вам допомогти!'
      }
    ]
  },
  {
    type: 'paragraph',
    children: [
      {
        type: 'text',
        text: 'Звертайтеся до нас за допомогою контактної інформації нижче або скористайтеся '
      },
      {
        type: 'link',
        url: contactRoute.path,
        children: [
          {
            type: 'text',
            text: 'контактною формою'
          }
        ]
      },
      {
        type: 'text',
        text: ", і ми зв'яжемося з вами найближчим часом."
      }
    ]
  },
  {
    type: 'heading',
    children: [
      {
        type: 'text',
        text: 'Контактна інформація:'
      }
    ],
    level: 3
  },
  {
    type: 'list',
    format: 'unordered',
    children: [
      {
        type: 'list-item',
        children: [
          {
            type: 'text',
            text: 'Телефон: '
          },
          {
            type: 'link',
            url: 'tel:+380505860456',
            children: [
              {
                type: 'text',
                text: '38 (050) 586 04 56'
              }
            ]
          },
          {
            text: '',
            type: 'text'
          }
        ]
      },
      {
        type: 'list-item',
        children: [
          {
            type: 'text',
            text: 'Електронна пошта: '
          },
          {
            type: 'link',
            url: 'mailto:welcome@smk-law.com.ua',
            children: [
              {
                type: 'text',
                text: 'welcome@smk-law.com.ua'
              }
            ]
          },
          {
            text: '',
            type: 'text'
          }
        ]
      }
    ]
  }
];

export default function BlogContactSection() {
  return (
    <section className={styles.section}>
      <BlogTextBlock body={body} />
    </section>
  );
}
