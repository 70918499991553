import {
  blogListPage,
  contactRoute,
  homepageRoute,
  servicesRoute,
  statementRoute,
  teamRoute
} from './routes';

export interface MenuItem {
  label: string;
  href: string;
}

export const menuItems: MenuItem[] = [
  {
    label: 'Головна',
    href: homepageRoute.path
  },
  {
    label: 'Позиція',
    href: statementRoute.path
  },
  {
    label: 'Команда',
    href: teamRoute.path
  },
  {
    label: 'Послуги',
    href: servicesRoute.path
  },
  {
    label: 'Новини',
    href: blogListPage.path
  },
  {
    label: 'Контакти',
    href: contactRoute.path
  }
];
