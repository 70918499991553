import React from 'react';
import styles from './TeamMembers.module.scss';
import { IFrontendTeamColumnsMember } from '../../types/strapi';
import { resolveUrl } from '../../lib/resolveUrl';

interface ITeamMembersProps {
  members: IFrontendTeamColumnsMember[];
}

export function TeamMembers({ members }: ITeamMembersProps) {
  return (
    <ul className={styles.list}>
      {members.map((member) => (
        <li key={member.id} className={styles.list__item}>
          <img
            src={resolveUrl(member.photo.data.attributes.formats.large.url)}
            alt={member.photo.data.attributes.alternativeText ?? member.name}
            className={styles.list__photo}
          />

          <h2 className={styles.list__name}>{member.name}</h2>

          <span className={styles.list__experience}>{member.experience}</span>
        </li>
      ))}
    </ul>
  );
}
