export interface IRoute {
  path: string;
  name?: string;
  description?: string;
}

export const homepageRoute: IRoute = {
  name: 'Головна',
  path: '/',
  description:
    '25+ років досвіду. Правова допомога у господарських, адміністративних, цивільних, сімейних, бізнесових та кримінальних справах.'
};

export const statementRoute: IRoute = {
  name: 'Позиція',
  path: '/statement',
  description:
    'Захист прав інтересів з високою якістю. Гарантія результату. Працюємо тільки на успіх.'
};

export const teamRoute: IRoute = {
  name: 'Команда',
  path: '/team'
};

export const contactRoute: IRoute = {
  name: 'Контакти',
  path: '/contact'
};

export const notFoundRoute: IRoute = {
  name: 'Сторінка Не Знайдена',
  path: '*'
};

// ----- Blog routes.

export const blogListPage: IRoute = {
  name: 'Новини',
  path: '/blog'
};

export const blogPage: IRoute = {
  path: '/blog/:slug'
};

// ----- Services routes.

export const servicesRoute: IRoute = {
  name: 'Послуги',
  path: '/services'
};

export const serviceRoute: IRoute = {
  path: '/services/:slug'
};

export const commercialLawRoute: IRoute = {
  path: '/services/commercial-law'
};

export const administrativeLawRoute: IRoute = {
  path: '/services/administrative-law'
};

export const civilLawRoute: IRoute = {
  path: '/services/civil-law'
};

export const familyLawRoute: IRoute = {
  path: '/services/family-law'
};

export const criminalLawRoute: IRoute = {
  path: '/services/criminal-law'
};

export const businessLawRoute: IRoute = {
  path: '/services/business-law'
};
