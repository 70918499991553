import { useEffect, useMemo } from 'react';
import { debounce } from 'lodash';

/**
 * Execute debounced callback on window resize.
 *
 * @param {function}  callback    Callback to execute
 * @param {boolean}   [isEnabled] Enable or disable callback
 * @param {number}    [wait]      Wait time for debounce
 */
export default function useWindowResize(
  callback: (e: Event) => void,
  isEnabled = true,
  wait = 50
): void {
  const debouncedCallback = useMemo(() => debounce(callback, wait), [callback]);

  useEffect(() => {
    if (!isEnabled) {
      return undefined;
    }

    window.addEventListener('resize', debouncedCallback);

    return () => {
      if (debouncedCallback) {
        debouncedCallback.cancel();
      }

      window.removeEventListener('resize', debouncedCallback);
    };
  }, [isEnabled, debouncedCallback]);
}
