import { useQuery } from 'react-query';
import { BlocksContent } from '@strapi/blocks-react-renderer';

export interface IStatementPageContent {
  id: number;
  attributes: {
    title: string;
    body: BlocksContent;
  };
}

export function useLoadStatementContent() {
  return useQuery({
    queryKey: ['statement'],
    queryFn: () =>
      fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/api/statement`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_API_KEY}`
        }
      })
        .then((res) => res.json())
        .then((x) => x.data as IStatementPageContent)
  });
}
