import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Homepage from '../../pages/Homepage/Homepage';
import StatementPage from '../../pages/StatementPage/StatementPage';
import TeamPage from '../../pages/TeamPage/TeamPage';
import ContactPage from '../../pages/ContactPage/ContactPage';
import {
  contactRoute,
  homepageRoute,
  notFoundRoute,
  servicesRoute,
  statementRoute,
  teamRoute,
  serviceRoute,
  blogListPage,
  blogPage
} from '../../lib/routes';
import ServicesPage from '../../pages/ServicesPage/ServicesPage';
import ScrollToTop from '../scrollToTop/scrollToTop';
import useKeyboardFocusDetection from '../../hooks/useKeyboardFocusDetection';
import { ServicePage } from '../../pages/ServicePage/ServicePage';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import BlogListPage from '../../pages/BlogListPage/BlogListPage';
import BlogPage from '../../pages/BlogPage/BlogPage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 3
    }
  }
});

export default function App() {
  useKeyboardFocusDetection();

  return (
    <BrowserRouter>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY!}>
        <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <Header />

          <Routes>
            <Route path={homepageRoute.path} element={<Homepage />} />
            <Route path={statementRoute.path} element={<StatementPage />} />
            <Route path={teamRoute.path} element={<TeamPage />} />
            <Route path={servicesRoute.path} element={<ServicesPage />} />
            <Route path={serviceRoute.path} element={<ServicePage />} />
            <Route path={contactRoute.path} element={<ContactPage />} />
            <Route path={blogListPage.path} element={<BlogListPage />} />
            <Route path={blogPage.path} element={<BlogPage />} />
            <Route path={notFoundRoute.path} element={<ErrorPage notFound />} />
          </Routes>

          <Footer />
        </QueryClientProvider>
      </GoogleReCaptchaProvider>
    </BrowserRouter>
  );
}
