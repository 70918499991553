import React, { useEffect, useState } from 'react';
import { ReactComponent as ChevronIcon } from '@material-design-icons/svg/round/chevron_right.svg';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styles from './FooterContactForm.module.scss';
import ContactModal from '../ContactModal/ContactModal';
import { useSubmitContactForm } from '../../api/forms';

export default function FooterContactForm(): React.ReactElement {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const mutation = useSubmitContactForm();
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Display modal on success.
  useEffect(() => {
    if (mutation.isSuccess) {
      setShowModal(true);
      setEmail('');
    }
  }, [mutation.isSuccess]);

  return (
    <>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();

          mutation.mutate({
            form: 'footer-form',
            email
          });
        }}
      >
        <p className={styles.form__text}>Напишіть свою електронну пошту і ми зв’яжемось з вами</p>

        <div className={styles.form__group}>
          <input
            onChange={(e) => setEmail(e.target.value)}
            className={styles.form__input}
            placeholder="Email"
            autoComplete="email"
            value={email}
            type="email"
            minLength={3}
            maxLength={255}
            required
          />

          <button
            className={styles.form__submit}
            type="submit"
            aria-label="Відправити"
            disabled={mutation.isSuccess || !executeRecaptcha}
          >
            <ChevronIcon />
          </button>
        </div>
      </form>

      <ContactModal onClose={() => setShowModal(false)} isOpen={showModal} />
    </>
  );
}
