import { useQuery } from 'react-query';
import { IFrontendTextBlockComponent, IStrapiMedia } from '../types/strapi';

export interface IArticleAuthor {
  id: number;
  attributes: {
    fullName: string;
    photo: {
      data: IStrapiMedia;
    };
    createdAt: string;
    publishedAt: string;
    updatedAt: string;
  };
}

export interface IBaseBlogPageContent {
  id: number;
  attributes: {
    title: string;
    slug: string;
    excerpt: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    cover: {
      data: IStrapiMedia;
    };
  };
}

export interface IBlogPageContent extends IBaseBlogPageContent {
  attributes: IBaseBlogPageContent['attributes'] & {
    body: IFrontendTextBlockComponent[];
    author: {
      data: IArticleAuthor;
    };
  };
}

export function useFindBlogPageBySlug(slug: string) {
  return useQuery({
    queryKey: ['blog', slug],
    queryFn() {
      const params = new URLSearchParams([
        ['populate', 'deep'],
        ['filters[slug][$eq]', slug]
      ]);

      return fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/api/articles?${params.toString()}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_API_KEY}`
        }
      })
        .then((res) => res.json())
        .then((x) => x.data[0] as IBlogPageContent);
    }
  });
}

export function useLoadBlogs(page: number) {
  return useQuery({
    queryKey: ['blog', page],
    queryFn() {
      const params = new URLSearchParams([
        ['sort', 'createdAt:desc'],
        ['populate', 'cover'],
        ['pagination[page]', `${page}`],
        ['pagination[pageSize]', '10']
      ]);

      return fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/api/articles?${params.toString()}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_API_KEY}`
        }
      })
        .then((res) => res.json())
        .then((x) => ({
          content: x.data as IBlogPageContent[],
          pageCount: x.meta.pagination.pageCount
        }));
    }
  });
}
