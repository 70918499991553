import React from 'react';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import Page from '../../components/Page/Page';
import styles from './TeamPage.module.scss';
import PageTitle from '../../components/PageTitle/PageTitle';
import ErrorPage from '../ErrorPage/ErrorPage';
import { useLoadTeamContent } from '../../api/team';
import { StrapiFrontendWidgetType } from '../../lib/constants';
import { TeamMembers } from '../../components/TeamMembers/TeamMembers';
import LoadingPageContent from '../../components/LoadingPageContent/LoadingPageContent';

export default function TeamPage() {
  const { isLoading, error, data } = useLoadTeamContent();

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Page pageTitle={data?.attributes.title} className={styles.page}>
      {isLoading || !data ? (
        <LoadingPageContent />
      ) : (
        <>
          <PageTitle>{data.attributes.title}</PageTitle>

          {data.attributes.body.map((item) => {
            const key = item.id + item.__component;

            switch (item.__component) {
              case StrapiFrontendWidgetType.textBlock:
                return (
                  <BlocksRenderer
                    key={key}
                    content={item.body}
                    blocks={{
                      // eslint-disable-next-line react/no-unstable-nested-components
                      paragraph: ({ children }) => <p className={styles.page__text}>{children}</p>
                    }}
                  />
                );

              case StrapiFrontendWidgetType.teamColumns:
                return <TeamMembers key={key} members={item.members} />;
              default:
                return null;
            }
          })}
        </>
      )}
    </Page>
  );
}
